.App {
  font-family: sans-serif;
  text-align: left;
}

.Up {
  color: green;
}

.Down {
  color: red;
}
